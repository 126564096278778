import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
}, {
  path: '/Home2',
  name: 'home2',
  component: () => import('../views/Home2.vue'),

}, {
  path: '/Home3',
  name: 'home3',
  component: () => import('../views/Home3.vue')
}, {
  path: '/province',
  name: 'province',
  component: () => import(/* webpackChunkName: "province" */ '../views/Home2/province.vue')
},
{
  path: '/city',
  name: 'city',
  component: () => import(/* webpackChunkName: "city" */ '../views/Home2/city.vue')
}, {
  path: '/map',
  name: 'HomeMap',
  component: () => import(/* webpackChunkName: "city" */ '../views/Home2/HomeMap.vue')
}, {
  path: '/HomeL24',
  name: 'HomeL24',
  component: () => import(/* webpackChunkName: "city" */ '../views/Home2/HomeL24.vue')
}
  // ,{
  //   path: '/map2',
  //   name: 'maps',
  //   component: () => import(/* webpackChunkName: "city" */ '../views/map/index.vue')
  // }
]
const router = new VueRouter({
  routes
})

export default router